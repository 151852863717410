import React, { useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { useStaticQuery, graphql } from 'gatsby';

import Layout from '../components/layout';

import './datenschutz.scss';

const DatenschutzPage = () => {
  const { company, privacy } = useStaticQuery(graphql`
    query {
      privacy {
        datenschutz
      }
      company {
        name
        phone
        postal_code
        address_line_1
        mail
        city
        contact_first_name
        contact_last_name
      }
    }
  `);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <div className="datenschutz-header">
        Name und Kontakt des Verantwortlichen gemäß Artikel 4 Abs. 7 DSGVO
      </div>
      <div style={{ width: '1280px', margin: '0 auto', marginTop: '80px' }}>
        <div className="datenschutz-text-header">Verteren durch</div>

        <div className="datenschutz-text">
          {company.name}
          {' '}
          <br />
          {company.contact_first_name}
          {' '}
          {company.contact_last_name}
          {' '}
          <br />
          {company.address_line_1}
          {' '}
          <br />
          {company.postal_code}
          {' '}
          {company.city}
        </div>

        <div className="datenschutz-text-header">Kontakt</div>

        <div className="datenschutz-text">
          Telefon:
          {' '}
          {company.phone}
          {' '}
          <br />
          E-Mail:
          {' '}
          {company.mail}
        </div>

        <ReactMarkdown source={privacy.datenschutz} />
      </div>
    </Layout>
  );
};

export default DatenschutzPage;
